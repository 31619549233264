<template>
  <div class="body">
    <div class="text-fields-block block-wide">
      <form @submit.prevent="add_customer">
        <customers-text-fields
          ref="add_customer"
          v-model="new_customer"
          @phone_focusin="phone_focusin"
          @phone_focusout="phone_focusout"
        ></customers-text-fields>
        <div
          class="
            text-fields-block__btn-row text-fields-block__btn-row_customers
          "
        >
          <loader v-if="info.loader"></loader>
          <custom-btn v-else class="button button_theme_blue" type="submit">{{
            $t("customers.add_customer")
          }}</custom-btn>
          <span
            class="text-fields-block__info-text text_color_green"
            :class="{
              text_color_red: info.error,
            }"
            >{{ info_text }}</span
          >
        </div>
      </form>
    </div>
    <div class="customers__table block-wide">
      <customers-table
        :header_items="headers"
        :items="customers"
        @change_sorted="change_sorted"
      ></customers-table>
    </div>
  </div>
</template>

<script>
import CustomersTextFields from "@/components/components/customers/customersTextFields";
import CustomBtn from "@/components/recurringComponents/customBtn";
import CustomersTable from "@/components/components/customers/customersTable";
import Loader from "@/components/recurringComponents/loader";
export default {
  name: "customers",
  components: {
    Loader,
    CustomersTable,
    CustomBtn,
    CustomersTextFields,
  },
  data() {
    return {
      info: {
        error: false,
        type: "",
        text: "",
        loader: false,
      },
      new_customer: {
        customer_id: null,
        password: "",
        legal_name: "",
        company: "",
        admin_name: "",
        mail: "",
        phone: "",
      },
      headers: [
        {
          name: "customer_id",
          title: this.$i18n.t("customers.headers.customer_id"),
          sorted: 1,
        },
        {
          name: "legal_name",
          title: this.$i18n.t("customers.headers.legal_name"),
          sorted: 0,
        },
        {
          name: "company",
          title: this.$i18n.t("customers.headers.company"),
          sorted: 0,
        },
        {
          name: "admin_name",
          title: this.$i18n.t("customers.headers.admin_name"),
          sorted: 0,
        },
        {
          name: "mail",
          title: this.$i18n.t("customers.headers.mail"),
          sorted: 0,
        },
        {
          name: "phone",
          title: this.$i18n.t("customers.headers.phone"),
          sorted: 0,
        },
      ],
    };
  },
  watch: {
    "$i18n.locale"() {
      console.log("Watch locale");
      for (let item of this.headers) {
        item.title = this.$t(`customers.headers.${item.name}`);
      }
    },
  },
  created() {
    this.$store.dispatch("load_customers");
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$socket.readyState === 1) {
        vm.$socket.sendObj({
          route: to.name,
          token: localStorage.getItem("access_token"),
        });
      }
    });
  },
  computed: {
    customers() {
      return this.$store.getters.CUSTOMERS.map((customer) => {
        return {
          id: customer.id,
          customer_id: customer.customer_id,
          legal_name: customer.company.legal_name,
          company: customer.company.name,
          admin_name: customer.name,
          mail: customer.login,
          phone: customer.phone,
          password: "",
        };
      });
    },
    info_text() {
      if (this.info.text) {
        return this.info.text;
      }
      switch (this.info.type) {
        case "required":
          return this.$i18n.t("errors.required");
        case "no_password":
          return this.$i18n.t("customers.errors.value");
        case "no_phone":
          return this.$i18n.t("customers.errors.no_phone");
        case "no_mail":
          return this.$i18n.t("customers.errors.no_mail");
        case "no_format":
          return this.$i18n.t("errors.format");
        case "id_exist":
          return this.$i18n.t("customers.loader.id_exist");
        case "mail_exist":
          return this.$i18n.t("customers.loader.mail_exist");
        case "progress":
          return this.$i18n.t("customers.loader.progress");
        case "failed":
          return this.$i18n.t("customers.loader.failed");
        case "success":
          return this.$i18n.t("customers.loader.success");
        default:
          return "";
      }
    },
  },
  methods: {
    change_sorted(item) {
      let change_item = this.headers.find((it) => it.name === item);
      let sort = change_item.sorted;

      this.headers.forEach((item) => (item.sorted = 0));

      if (sort !== 1) change_item.sorted = 1;
      else change_item.sorted = -1;

      if (change_item.sorted !== 0) {
        this.customers.sort((a, b) => {
          if (a[item] > b[item]) return change_item.sorted;
          if (a[item] === b[item]) return 0;
          if (a[item] < b[item]) return -change_item.sorted;
        });
      }
    },

    validate() {
      let valid = this.$refs.add_customer.validate();
      this.info.error = !valid.valid;
      this.info.type = valid.error;
      return !this.info.error;
    },
    add_customer() {
      if (this.validate()) {
        this.info.loader = true;
        this.info.type = "progress";
        this.$store
          .dispatch("add_customer", this.new_customer)
          .then(() => {
            this.info.type = "success";
            this.new_customer = {
              customer_id: null,
              password: "",
              legal_name: "",
              company: "",
              admin_name: "",
              mail: "",
              phone: "",
            };
          })
          .catch((error) => {
            this.info.error = true;
            this.info.text = error.response?.data?.errors.find(
              (x) => x
            )?.message;
          })
          .finally(() => {
            this.info.loader = false;
            setTimeout(() => {
              this.info.type = "";
              this.info.text = "";
              this.info.error = false;
            }, 3000);
          });
      }
    },
    phone_focusin() {
      if (this.new_customer.phone == "") {
        this.new_customer.phone = "+7";
      }
    },
    phone_focusout() {
      if (this.new_customer.phone === "+7") this.new_customer.phone = "";
    },
  },
};
</script>

<style scoped></style>
