<template>
  <div class="change-block">
    <customers-text-fields
      ref="fields"
      v-model="change_item"
      :for_table="true"
      :no_pass="true"
    ></customers-text-fields>

    <div v-if="info.loader" class="change-block__btn-block">
      <div class="change-block__info-block">
        <loader></loader>
        <span
          class="text-fields-block__info-text text_color_green"
          :class="{ text_color_red: info.error }"
          >{{ info_text }}</span
        >
      </div>
    </div>
    <div v-else class="change-block__btn-block">
      <div>
        <custom-btn class="button button_theme_blue" @click="edit_customer">{{
          $t("customers.change_block.save")
        }}</custom-btn>
        <simple-button
          :title="$t('customers.change_block.cancel')"
          @click="$emit('cancel')"
        ></simple-button>
        <span
          class="text_color_green"
          :class="{ text_color_red: info.error }"
          >{{ info_text }}</span
        >
      </div>
      <custom-btn
        class="change-block__delete-button button button_theme_delete"
        @click="$emit('delete')"
      >
        <span>{{ $t("customers.change_block.delete") }}</span>
        <div class="change-block__delete-icon"></div>
      </custom-btn>
    </div>
  </div>
</template>

<script>
import CustomBtn from "@/components/recurringComponents/customBtn";
import SimpleButton from "@/components/recurringComponents/simpleButton";
import CustomersTextFields from "@/components/components/customers/customersTextFields";
import Loader from "@/components/recurringComponents/loader";

export default {
  name: "customersChangeBlock",
  components: {
    Loader,
    CustomersTextFields,
    SimpleButton,
    CustomBtn,
  },
  props: ["change_item"],
  data() {
    return {
      info: {
        error: false,
        type: "",
        loader: false,
      },
    };
  },
  computed: {
    info_text() {
      switch (this.info.type) {
        case "required":
          return this.$i18n.t("errors.required");
        case "no_password":
          return this.$i18n.t("customers.errors.value");
        case "no_phone":
          return this.$i18n.t("customers.errors.no_phone");
        case "no_mail":
          return this.$i18n.t("customers.errors.no_mail");
        case "no_format":
          return this.$i18n.t("errors.format");
        case "id_exist":
          return this.$i18n.t("customers.loader.id_exist");
        case "progress":
          return this.$i18n.t("customers.loader.progress_edit");
        case "failed":
          return this.$i18n.t("customers.loader.failed_edit");
        case "success":
          return this.$i18n.t("customers.loader.success_edit");
        default:
          return "";
      }
    },
  },
  methods: {
    validate() {
      this.info.error = false;
      this.info.type = "";
      for (let key in this.$refs) {
        let valid = this.$refs[key].validate();
        if (!valid.valid) {
          this.info.error = true;
          this.info.type = valid.error;
        }
      }
      return !this.info.error;
    },
    edit_customer() {
      if (this.validate()) {
        this.info.loader = true;
        this.info.type = "progress";
        this.$store
          .dispatch("update_customer", this.change_item)
          .then(() => {
            this.info.type = "success";
            this.info.error = false;
          })
          .catch(() => {
            this.info.error = true;
            this.info.type = "failed";
          })
          .finally(() => {
            this.info.loader = false;
          });
      }
    },
  },
};
</script>

<style scoped></style>
