<template>
  <div class="table">
    <div class="table__header">
      <div
        class="table__cell table__header-item"
        v-for="item in header_items"
        :key="item.name"
        :class="item.name"
        :style="item.name === active_name ? active_style : ''"
        @click="
          $emit('change_sorted', item.name);
          set_active_style(item.name);
        "
      >
        <span class="table__header-title">{{ item.title }}</span>
        <div class="table__header-arrows">
          <div
            class="table__header-arrow table__header-top-arrow"
            :class="{
              'table__header-arrow_pressed': item.sorted === 1,
            }"
          ></div>
          <div
            class="table__header-arrow table__header-bottom-arrow"
            :class="{
              'table__header-arrow_pressed': item.sorted === -1,
            }"
          ></div>
        </div>
      </div>
      <div
        class="table__cell table__header-item customers-table__img-header"
      ></div>
    </div>
    <div class="table__data">
      <template v-for="item in items">
        <div class="table__row" :key="item.hub_id">
          <div v-if="show_change_item !== item" class="table-2__cells">
            <template v-for="cell in Object.keys(item)">
              <div
                class="table__cell"
                :key="cell + '1'"
                v-if="show_column(cell)"
                @click="set_active_style(cell)"
                :class="cell"
                :style="cell === active_name ? active_style : ''"
              >
                <div class="table__cell-text">{{ item[cell] }}</div>
              </div>
            </template>
            <div
              class="table__cell table-2__img-cell customers-table__img-cell"
            >
              <edit-btn @click="set_show_change_item(item)"></edit-btn>
              <delete-btn @click="call_confirm_dialog(item)"></delete-btn>
            </div>
          </div>
          <div
            class="table-2__change-items table-2__change-items_customers"
            v-else
          >
            <customers-change-block
              :change_item="show_change_item"
              @cancel="close_change_block"
              @delete="call_confirm_dialog(item)"
            ></customers-change-block>
          </div>
        </div>
      </template>
    </div>
    <confirm
      :enable="show_confirm"
      src="confirm_delete.svg"
      :button_text="$t('customers.confirm_button')"
      :text="$t('customers.confirm_text')"
      text_width="280"
      @cancel="close_confirm_dialog"
      @confirm="confirm_delete"
    ></confirm>
  </div>
</template>

<script>
import Confirm from "@/components/recurringComponents/confirmDialog";
import customersChangeBlock from "@/components/components/customers/customersChangeBlock";
import EditBtn from "@/components/recurringComponents/editBtn";
import DeleteBtn from "@/components/recurringComponents/deleteBtn";

export default {
  name: "customersTable",
  components: { DeleteBtn, EditBtn, customersChangeBlock, Confirm },
  props: {
    header_items: Array,
    items: Array,
  },
  data() {
    return {
      show_confirm: false,
      being_delete_customer: null,
      show_change_item: null,
      active_name: "",
      active_style: "",
    };
  },
  watch: {
    items: {
      handler: function () {
        this.active_name = "";
      },
      deep: true,
    },
  },
  methods: {
    show_cell(item) {
      return this.header_items.find((header) => header.name === item);
    },
    show_column(cell) {
      if (this.header_items.length === 0) return true;

      let col = false;
      this.header_items.forEach((item) => {
        if (item.name === cell) {
          col = true;
        }
      });
      return col;
    },
    call_confirm_dialog(item) {
      this.being_delete_customer = item;
      this.show_confirm = true;
    },
    close_confirm_dialog() {
      this.being_delete_hub = null;
      this.show_confirm = false;
    },
    confirm_delete() {
      this.$store
        .dispatch("remove_customer", this.being_delete_customer)
        .finally(() => {
          this.show_confirm = false;
        });
    },
    set_show_change_item(item) {
      this.show_change_item = item;
    },
    close_change_block() {
      this.show_change_item = null;
      this.$store.dispatch("load_customers");
    },
    set_active_style(name) {
      let active_width = 0;
      let fn = false;

      let collection = document.getElementsByClassName(name);
      for (let item of collection) {
        if (
          item.scrollWidth > item.clientWidth &&
          item.scrollWidth > active_width
        ) {
          active_width = item.scrollWidth;
          fn = true;

          item.addEventListener("transitionend", this.set_overflow);
        }
      }

      if (fn) {
        this.active_name = name;
        this.active_style = `flex: 0 0 ${active_width}px;`;
      }
    },

    set_overflow() {
      this.active_style += "overflow: visible";
    },
  },
};
</script>

<style scoped></style>
