<template>
  <div
    class="text-fields-block__row text-fields-block__row_customer"
    :class="{ 'text-fields-block__row_customer-change': for_table }"
  >
    <div
      class="text-fields-block__element text-fields-block__element_customer"
      :class="{ 'text-fields-block__element_customer-change': for_table }"
    >
      <span :class="{ invalid_field: this.invalid.customer_id }">{{
        $t("customers.customer_id")
      }}</span>
      <custom-number-field
        ref="customer_id"
        :rules="id_rule"
        class="text-fields-block__field"
        v-model="new_customer.customer_id"
        :max="65535"
        :min="0"
        @change="refresh"
        :placeholder="$t('customers.text_field_hint')"
        :disabled="for_table"
      ></custom-number-field>
    </div>

    <div
      class="text-fields-block__element text-fields-block__element_customer"
      :class="{ 'text-fields-block__element_customer-change': for_table }"
    >
      <span :class="{ invalid_field: this.invalid.company }">{{
        $t("customers.company")
      }}</span>
      <custom-text-field
        ref="company"
        :rules="[rules.require, rules.max_length_50]"
        class="text-fields-block__field"
        v-model="new_customer.company"
        @change="refresh"
        :placeholder="$t('customers.text_field_hint')"
      ></custom-text-field>
    </div>

    <div
      class="text-fields-block__element text-fields-block__element_customer"
      :class="{ 'text-fields-block__element_customer-change': for_table }"
    >
      <span :class="{ invalid_field: this.invalid.legal_name }">{{
        $t("customers.legal_name")
      }}</span>
      <custom-text-field
        ref="legal_name"
        :rules="[rules.require, rules.max_length_50]"
        class="text-fields-block__field"
        v-model="new_customer.legal_name"
        @change="refresh"
        :placeholder="$t('customers.text_field_hint')"
      ></custom-text-field>
    </div>

    <div
      class="text-fields-block__element text-fields-block__element_customer"
      :class="{ 'text-fields-block__element_customer-change': for_table }"
    >
      <span :class="{ invalid_field: this.invalid.admin_name }">{{
        $t("customers.admin_name")
      }}</span>
      <custom-text-field
        ref="admin_name"
        :rules="[rules.require, rules.max_length_50]"
        class="text-fields-block__field"
        v-model="new_customer.admin_name"
        @change="refresh"
        :placeholder="$t('customers.text_field_hint')"
      ></custom-text-field>
    </div>

    <div
      class="text-fields-block__element text-fields-block__element_customer"
      :class="{ 'text-fields-block__element_customer-change': for_table }"
    >
      <span :class="{ invalid_field: this.invalid.mail }">{{
        $t("customers.mail")
      }}</span>
      <custom-text-field
        ref="mail"
        :rules="mail_rule"
        class="text-fields-block__field"
        v-model="new_customer.mail"
        @change="refresh"
        :placeholder="$t('customers.text_field_hint')"
      ></custom-text-field>
    </div>

    <div
      class="text-fields-block__element text-fields-block__element_customer"
      :class="{ 'text-fields-block__element_customer-change': for_table }"
    >
      <span :class="{ invalid_field: this.invalid.phone }">{{
        $t("customers.phone")
      }}</span>
      <custom-text-field
        ref="phone"
        :rules="[rules.require, rules.is_phone]"
        class="text-fields-block__field"
        v-model="new_customer.phone"
        @change="refresh"
        @focusin="phone_focusin"
        @focusout="phone_focusout"
        :placeholder="$t('customers.text_field_hint')"
      ></custom-text-field>
    </div>

    <div
      class="text-fields-block__element text-fields-block__element_customer"
      :class="{ 'text-fields-block__element_customer-change': for_table }"
    >
      <span :class="{ invalid_field: this.invalid.password }">{{
        $t("customers.password")
      }}</span>
      <custom-text-field
        v-if="!no_pass"
        ref="password"
        :rules="[rules.require, rules.password]"
        class="text-fields-block__field"
        v-model="new_customer.password"
        @change="refresh"
        :placeholder="$t('customers.text_field_hint')"
        ><simple-button
          class="text-fields-block__generate-btn"
          :title="$t('customers.generate')"
          @click="generate"
        ></simple-button
      ></custom-text-field>
      <custom-text-field
        v-else
        ref="password"
        :rules="[rules.password]"
        class="text-fields-block__field"
        v-model="new_customer.password"
        @change="refresh"
      ></custom-text-field>
    </div>
  </div>
</template>

<script>
import CustomTextField from "@/components/recurringComponents/customTextField";
import SimpleButton from "@/components/recurringComponents/simpleButton";
import rules from "@/rules";
import CustomNumberField from "@/components/recurringComponents/customNumberField";
export default {
  name: "customersTextFields",
  props: {
    value: {
      customer_id: String,
      password: String,
      legal_name: String,
      admin_name: String,
      company: String,
      mail: String,
      phone: String,
    },
    for_table: Boolean,
    no_pass: {
      type: Boolean,
      default: false,
    },
  },
  components: { CustomNumberField, SimpleButton, CustomTextField },
  data() {
    return {
      // new_customer: this.value,
      rules: rules,
      invalid: {
        customer_id: false,
        company: false,
        legal_name: false,
        admin_name: false,
        mail: false,
        phone: false,
        password: false,
      },
    };
  },
  computed: {
    new_customer() {
      return this.value;
    },
    id_rule() {
      return [(v) => !!v || "required", (v) => this.id_exist(v) || "id_exist"];
    },
    mail_rule() {
      let reg = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      return [
        (v) => !!v || "required",
        (v) => reg.test(v) || "no_mail",
        (v) => this.email_exist(v) || "mail_exist",
      ];
    },
  },
  methods: {
    refresh() {
      this.$emit("change", { value: this.new_customer });
    },
    id_exist(value) {
      let dubl = this.$store.getters.CUSTOMERS.find((customer) => {
        return Number(customer.customer_id) === value;
      });
      if (dubl !== undefined) {
        return false;
      }
      return true;
    },
    email_exist(value) {
      if (this.for_table && this.new_customer.mail === value) {
        return true;
      }
      let dubl = this.$store.getters.CUSTOMERS.find((customer) => {
        return customer.login === value;
      });
      if (dubl !== undefined) {
        return false;
      }
      return true;
    },
    generate() {
      let symb =
        "!@#$%^&*()\\[]{}-_+=~`|:;\"'<>,./?]abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      symb = Array.from(symb);
      let pass = "";
      for (let i = 0; i <= Math.round(Math.random() * 4) + 10; i++) {
        let s = symb[Math.round(Math.random() * (symb.length - 1))];
        pass = pass + s;
      }

      if (rules.password(pass) === true) {
        this.new_customer.password = pass;
        this.refresh();
      } else this.generate();
    },
    validate() {
      let error = false;
      let error_type = "";
      for (let key in this.$refs) {
        if (
          key === "password" &&
          this.no_pass &&
          this.new_customer.password === ""
        ) {
          continue;
        }
        let valid = this.$refs[key].validate();
        this._data["invalid"][key] = !valid.valid;
        if (!valid.valid) {
          error_type = valid.error;
          error = true;
        }
      }
      // if (!this.for_table && this.id_exist()) {
      //   return { valid: false, error: "id_exist" };
      // } else
      return { valid: !error, error: error_type };
    },
    phone_focusin() {
      this.$emit("phone_focusin");
    },
    phone_focusout() {
      this.$emit("phone_focusout");
    },
  },
};
</script>

<style scoped></style>
